<template>
    <div class="main">
        <van-nav-bar
                class="navi"
                left-arrow  @click-left="onClickLeft" title="登录" />
        <div class="icon-content">
            <img class="header-icon" src="../../assets/img/login_dlbj@3x.png"/>
        </div>
        <div class="content">
            <div class="conntent-header">
                <img class="conntent-header-icon" src="../../assets/img/login_tb@3x.png"/>
            </div>
            <div class="content-input">
                <van-field class="input" v-model="loginMes.userid" placeholder="请输入身份证号或手机号">
                    <template #left-icon>
                        <div>
                            <img class="field_icon" src="../../assets/img/login_yhm@3x.png"/>
                        </div>
                    </template>
                </van-field>
                <van-field class="input" v-model="loginMes.pwd" :type="isshow? 'text': 'password'"
                           placeholder="请输入登录密码">
                    <template #left-icon>
                        <div>
                            <img class="field_icon" src="../../assets/img/login_mm@3x.png"/>
                        </div>
                    </template>
                    <template #right-icon>
                        <div @click="showPassWord">
                            <img class="field_icon"
                                 :src="isshow? require('@/assets/img/'+input_img_kj_Src): require('@/assets/img/'+input_img_bk_Src) "/>
                        </div>
                    </template>
                </van-field>
                <van-field class="input" v-model="loginMes.yzm" placeholder="请输入验证码">
                    <template #left-icon>
                        <div>
                            <img class="field_icon" src="../../assets/img/login_yzm@3x.png"/>
                        </div>
                    </template>
                    <template #right-icon>
                        <div @click="yzmAction">
                            <img class="yzm_img" ref="yzm_img" />
                        </div>
                    </template>
                </van-field>
            </div>
            <div class="foot">
                <van-button color="linear-gradient(to right, #5252ff, #a066ff)" class="login_btn" round type="primary" @click="loginAction">立即登录</van-button>
            </div>
        </div>

    </div>
    <!-- 遮罩loading -->
    <van-overlay :show="showload" @click="showload = false">
        <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>
</template>

<script>
    import {defineComponent, reactive, ref, onMounted, getCurrentInstance} from 'vue'
    import getUserInfo from '@/mixin/getUserInfo.js'
    import loginRequest from '../../api/login'
    import { Dialog, Notify } from 'vant';
    import {useStore} from 'vuex';

    export default defineComponent({
        name: "login",
        setup(){
            const { proxy } = getCurrentInstance();
            const yzm_img = ref(null);
            const isshow = ref(false);
            const loginMes = reactive({
                userid: "",
                pwd: "",
                yzm: ""
            });
            const showPassWord =  ()=>{
              isshow.value = !isshow.value;
            };
            const showload = ref(false);
            const store = useStore();
            const loginAction = ()=>{
                if (loginMes.userid.length == 0){
                    Notify({ type: 'warning', message: '请输入用户名或手机号' });
                    return
                }
                if (loginMes.pwd.length == 0) {
                    Notify({ type: 'warning', message: '请输入密码' });
                    return
                }
                if (loginMes.yzm.length == 0) {
                    Notify({ type: 'warning', message: '请输入验证码' });
                    return
                }
                showload.value=true;
                loginRequest.wx_login(loginMes).then(res=>{
                    if (res.returnCode == "00"){
                        getUserInfo(proxy.$root.$route, store);
                        Dialog.alert({message: res.returnMessage}).then(()=>{
                            proxy.$root.$router.go(-1)
                        });
                    }
                    showload.value=false;
                });
            };
            const yzmAction = ()=>{
                loginRequest.wx_get_yzm().then(res=>{
                    let base64 =  "data:image/png;base64, " + res.rows;
                    yzm_img.value.src = base64;
                });
            };
            const onClickLeft = ()=> {
                window.history.back(-1);
            };
            onMounted(()=>{
                yzmAction();
            });
            return{
                yzm_img,
                showPassWord,
                loginMes,
                isshow,
                input_img_kj_Src: "nlogin_kj@3x.png",
                input_img_bk_Src: "nlogin_bkj@3x.png",
                loginAction,
                onClickLeft,
                yzmAction,
                showload
            };
        }
    });
</script>

<style lang="less" scoped>
    .van-nav-bar{
        background: transparent;
        margin: 0px;
        padding: 0px;
        font-size: 23px;
    }
    .van-hairline--bottom:after {
        border-bottom-width: 0px;
    }
    .main{
        margin: 0px;
        padding: 0px;
        background: linear-gradient(to bottom, #5252ff  0%,#a066ff 100%);
        position: relative;
        min-height: 100vh;
        .icon-content{
            text-align: center;
            .header-icon{
                width: 248px;
                margin: 0 auto;
            }
        }
        .content {
            width: 80%;
            background: white;
            border: #eee solid 1px;
            box-shadow: 10px 15px 0px #A188FF;
            border-radius: 8px;
            position: absolute;
            top: 30%;
            right: 10%;
            .conntent-header{
                text-align: center;
                position: absolute;
                width: 100%;
                .conntent-header-icon{
                    width: 115px;
                    position: relative;
                    top: -57.5px;
                }
            }
            .content-input{
                margin-top: 100px;
                .input {
                    border-bottom: #ccc solid 0.8px;
                    width: 90%;
                    margin: 10px 5%;
                    height: 55px;
                    .field_icon {
                        width: 25px;
                        height: 25px;
                        margin: 3px 10px 0 0;
                    }
                    .yzm_img{
                        height: 30px;
                    }
                }
            }
            .foot {
                text-align: center;
                .login_btn {
                    width: 80%;
                    margin-top: 30px;
                    margin-bottom: 40px;
                }
            }
        }
    }
</style>